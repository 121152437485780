<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <item-edit-view
      title="Болезнь"
      :subtitle="item ? item.name : null"
      :loading="loading"
    >
      <template v-slot:right v-if="item && !loading">
        <v-btn
        @click="copy"
        icon
        title="Скопировать"
      >
        <v-icon small>fa-copy</v-icon>
      </v-btn>

        <v-btn
          :href="previewUrl"
          target="_blank"
          title="Превью"
          icon
          class="mr-4"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          right
          color="success"
          :loading="saving"
          @click.prevent="handleSubmit(save)"
        >
          <v-icon small>mdi-content-save</v-icon>
          Сохранить
        </v-btn>
      </template>

      <v-card>
        <v-form v-if="!loading && item">

          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab key="info">Инфо</v-tab>
            <v-tab key="photo">Фото</v-tab>
            <!-- <v-tab key="faq">FAQ</v-tab> -->
          </v-tabs>

          <v-tabs-items v-model="tab">

            <v-tab-item key="info">
              <v-card flat>
                <v-card-text>
                  <v-select
                    v-model="item.status"
                    :items="statuses"
                    label="Статус"
                  >
                  </v-select>

                  <validation-provider
                    v-slot="{ errors }"
                    name="Название"
                    rules="required"
                  >
                    <v-text-field
                      v-model="item.name"
                      label="Название"
                      :error-messages="errors"
                      required
                    ></v-text-field>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="Uri"
                    rules="required"
                  >
                    <slug-editor
                      name="uri"
                      class="mb-12 mt-4"
                      :input="item.name"
                      label="URL-адрес статьи"
                      placeholder="URL-адрес статьи"
                      v-model="item.slug"
                      :error="errors[0]"
                      persistent-hint
                      hide-details="auto"
                      :path="publicUrl"
                    />
                  </validation-provider>

                  <div class="mb-4">
                    <label for="symptoms">Симптомы</label>
                    <multiselect v-model="item.symptoms"
                       id="symptoms"
                       @search-change="findSymptoms"
                       :loading="symptoms.isLoading"
                       :internal-search="false"
                       :options="symptoms.items"
                       :allow-empty="true"
                       :show-labels="true"
                       :searchable="true"
                       :taggable="true"
                       :multiple="true"
                       placeholder="Симптомы"
                       track-by="id"
                       label="name"
                    >
                    </multiselect>
                  </div>

                  <div class="mb-4">
                    <label for="procedures">Процедуры и манипуляции</label>
                    <multiselect v-model="item.procedures"
                       id="procedures"
                       @search-change="findProcedures"
                       :loading="procedures.isLoading"
                       :internal-search="false"
                       :options="procedures.items"
                       :allow-empty="true"
                       :show-labels="true"
                       :searchable="true"
                       :taggable="true"
                       :multiple="true"
                       placeholder="Процедуры и манипуляции"
                       track-by="id"
                       label="name"
                    >
                    </multiselect>
                  </div>

                  <div class="mb-4">
                    <label for="relatedDiseases">Связанные заболевания</label>
                    <multiselect v-model="item.relatedDiseases"
                       id="relatedDiseases"
                       @search-change="findDiseases"
                       :loading="diseases.isLoading"
                       :internal-search="false"
                       :options="diseases.items"
                       :allow-empty="true"
                       :show-labels="true"
                       :searchable="true"
                       :taggable="true"
                       :multiple="true"
                       placeholder="Заболевания"
                       track-by="id"
                       label="name"
                    >
                    </multiselect>
                  </div>

                  <div class="mb-4">
                    <label for="specialities">Лечащий врач (специальность)</label>
                    <multiselect v-model="item.specialities"
                       id="specialities"
                       :loading="specialities.isLoading"
                       :options="specialities.items"
                       :allow-empty="true"
                       :show-labels="true"
                       :searchable="true"
                       :taggable="true"
                       :multiple="true"
                       placeholder="Cпециальности"
                       track-by="id"
                       label="name"
                    >
                    </multiselect>
                  </div>

                  <div class="mb-8">
                    <label for="doctors">Лечащий врач (доктор)</label>
                    <multiselect v-model="item.doctors"
                       id="doctors"
                       @search-change="findDoctors"
                       :loading="doctors.isLoading"
                       :internal-search="false"
                       :options="doctors.items"
                       :allow-empty="true"
                       :show-labels="true"
                       :searchable="true"
                       :taggable="true"
                       :multiple="true"
                       placeholder="Доктор"
                       track-by="id"
                       label="fullName"
                    >
                    </multiselect>
                  </div>

                  <div>
                    <v-input id="facts" :value="item.facts">
                      <quill v-model="item.facts" :options="quillToolbar" label="Цифры и факты"/>
                    </v-input>
                  </div>

                  <div>
                    <v-input :value="item.about">
                      <quill v-model="item.about" :options="quillToolbar" label="О болезни"/>
                    </v-input>
                  </div>

                  <div>
                    <v-input :value="item.whenToSeeADoctor">
                      <quill v-model="item.whenToSeeADoctor" :options="quillToolbar" label="Когда обращаться к врачу"/>
                    </v-input>
                  </div>

                  <div>
                    <v-input :value="item.diagnosis">
                      <quill v-model="item.diagnosis" :options="quillToolbar" label="Диагностика заболевания"/>
                    </v-input>
                  </div>

                  <div>
                    <v-input :value="item.treat">
                      <quill v-model="item.treat" :options="quillToolbar" label="Лечение заболевания"/>
                    </v-input>
                  </div>

                  <div>
                    <v-input :value="item.rehab">
                      <quill v-model="item.rehab" :options="quillToolbar" label="Реабилитация"/>
                    </v-input>
                  </div>

                  <div>
                    <v-input :value="item.howToPrepare">
                      <quill v-model="item.howToPrepare" :options="quillToolbar" label="Как подготовиться к визиту"/>
                    </v-input>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item key="photo">
              <v-card flat>
                <v-card-text>
                  <v-btn @click="choosePhoto" class="mr-1">{{ item.photo ? 'Изменить' : 'Выбрать' }}</v-btn>
                  <v-btn color="error" v-if="item.photo" @click="removePhoto">Удалить</v-btn>
                  <v-img v-if="item.photo" :src="item.photo.url" max-width="1024" contain class="mt-2"/>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- <v-tab-item key="faq">
              <v-card>
                <v-card-text>
                  <Faq :items="[]" />
                </v-card-text>
              </v-card>
            </v-tab-item> -->

          </v-tabs-items>
        </v-form>
      </v-card>
    </item-edit-view>
  </ValidationObserver>
</template>

<script>
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import diseasesService from '../../services/diseases'
import symptomsService from '../../services/symptoms'
import doctorsService from '../../services/doctors'
import proceduresService from '../../services/procedures'
import specialitiesService from '../../services/specialities'
import { QUILL_EXTENDED } from '../../components/elements/quilljs/configs'
import debounce from "lodash/debounce"
import { STATUS_LABELS } from './articleStatus'
import SlugEditor from '../../components/Slug/SlugEditor.vue'
import WhatDoWeTreatItem from './WhatDoWeTreatItem.vue'
// import Faq from './Faq.vue'

export default {
  extends: WhatDoWeTreatItem,
  components: {
    SlugEditor,
    // Faq
  },
  mixins: [createOrUpdateViewMixin(diseasesService)],
  data () {
    return {
      quillToolbar: QUILL_EXTENDED,
      tab: null,
      statuses: STATUS_LABELS,
      symptoms: {
        items: [],
        isLoading: false,
      },
      procedures: {
        items: [],
        isLoading: false,
      },
      diseases: {
        items: [],
        isLoading: false,
      },
      doctors: {
        items: [],
        isLoading: false,
      },
      specialities: {
        items: [],
        isLoading: false,
      },
      uuid: this.$route.params.id
    }
  },
  beforeRouteEnter (to, from, next) {
    let specialities = specialitiesService.getAll({ limit: 60 })
    Promise.all([specialities]).then(
      (values) => {
        next(vm => {
          vm.specialities.items = values[0]
        })
      },
      () => { next(false) }
    )
  },
  computed: {
    api () {
      return diseasesService
    },
    slug () {
      return this.item.slug
    },
    id () {
      return this.item.id
    }
  },
  methods: {
    choosePhoto () {
      this.openPhotoModal().$once('choose', (image) => {
        this.item.photo = image
      })
    },
    removePhoto () {
      this.item.photo = null
    },
    findDoctors: debounce (async function (query) {
      if (this.doctors.isLoading) {
        return
      }
      this.doctors.isLoading = true
      try {
        let r = await doctorsService.getAll({ search: query })
        this.doctors.items = r.items
        this.doctors.isLoading = false
      } catch (e) {
        console.error(e)
        this.doctors.isLoading = false
      }
    }, 1000),
    findProcedures: debounce (async function (query) {
      if (this.procedures.isLoading) {
        return
      }
      this.procedures.isLoading = true
      try {
        let r = await proceduresService.getAll({ search: query })
        this.procedures.items = r.items
        this.procedures.isLoading = false
      } catch (e) {
        console.error(e)
        this.procedures.isLoading = false
      }
    }, 1000),
    findDiseases: debounce (async function (query) {
      if (this.diseases.isLoading) {
        return
      }
      this.diseases.isLoading = true
      try {
        let r = await diseasesService.getAll({ search: query })
        this.diseases.items = r.items
        this.diseases.isLoading = false
      } catch (e) {
        console.error(e)
        this.diseases.isLoading = false
      }
    }, 1000),
    findSymptoms: debounce (async function (query) {
      if (this.symptoms.isLoading) {
        return
      }
      this.symptoms.isLoading = true
      try {
        let r = await symptomsService.getAll({ search: query })
        this.symptoms.items = r.items
        this.symptoms.isLoading = false
      } catch (e) {
        console.error(e)
        this.symptoms.isLoading = false
      }
    }, 1000),
    async copy () {
      await diseasesService.copy(this.id)
    }
  }
}
</script>
